::-webkit-scrollbar {
  width: 0;
}
body {
  background-color: #ccc;
  min-width: 400px;
}
.MuiDialog-paper {
  background-color: #ccc;
}
.MuiCard-root {
  background-color: #aaa;
}
.MuiDialog-container {
  align-items: start;
}
.MuiDialogTitle-root {
  padding: 16px;
}
.MuiDialogTitle-root + .MuiDialogContent-root {
  padding: 0px 16px;
}
.MuiDialogActions-root {
  padding: 16px;
}
.grabbable:hover {
  cursor: grab;
}
.grabbable:active {
  cursor: grabbing;
}

.MuiToolbar-root {
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
}

.MuiDrawer-paper {
  color: black;
  background-color: #ccc;
}

.footer {
  height: 80px;
}
.MuiListItemIcon-root {
  min-width: 0px;
  margin-right: 8px;
}

.MuiListItemIcon-root {
  color: black;
}
